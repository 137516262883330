import * as React from "react"
import Hero from "../roaster/hero"
import Oppurtunity from "../roaster/oppurtunity"
import TeamWork from "../roaster/teamwork"
import Join from "../roaster/join"
import Micro from "../roaster/micro"
import Experience from "../roaster/experience"
import Relationship from "../roaster/relationship"
import Services from "../roaster/services"

import heroimage from "../../assets/images/microhero.jpg"
import Fredrik from "../../assets/images/Fredrik_Lo_fbergs.webp"
import f1 from "../../assets/images/icons/noun-global-3394124.svg"
import f2 from "../../assets/images/icons/noun-invoice-3394132.svg"
import f3 from "../../assets/images/icons/group-28.svg"
import f4 from "../../assets/images/icons/noun-mobile-3394126.svg"
import f5 from "../../assets/images/icons/group-39.svg"
import f6 from "../../assets/images/icons/noun-tablet-3394141.svg"
import bulb from "../../assets/images/icons/bulb.svg"
import globe from "../../assets/images/icons/globe.svg"
import thumb from "../../assets/images/icons/thumb.svg"
import market from "../../assets/images/icons/market.svg"
import time from "../../assets/images/icons/time.svg"
import communication from "../../assets/images/icons/invention.svg"
import chart4 from "../../assets/images/icons/chart.svg"
import micro1 from "../../assets/images/mic1.jpg"
import micro2 from "../../assets/images/mic2.jpg"
import micro3 from "../../assets/images/mic3.jpg"
import Origin from "../roaster/origin"

const RoasterPage = ({ pageContext: { translate } }) => {
  const dataOppurtunity = [
    {
      icon: globe,
      title: translate.t?.global_movement,
      description: translate.t?.access_new_highend,
    },
    {
      icon: thumb,
      title: translate.t?.advantage,
      description: translate.t?.competitive_advantage,
    },
    {
      icon: bulb,
      title: translate.t?.efficiency,
      description: translate.t?.sc_efficiency_flexible,
    },
  ]

  const dataTeam = [
    {
      icon: time,
      title: translate.t?.time_investment,
      description: translate.t?.spend_time_understanding_values,
    },
    {
      icon: communication,
      title: translate.t?.reinvention,
      description: translate.t?.participate_improve_take_charge,
    },
    {
      icon: market,
      title: translate.t?.marketing_communication,
      description: translate.t?.spread_word_mission_transparency,
    },
    {
      icon: chart4,
      title: translate.t?.go_to_market_initiatives,
      description: translate.t?.roasters_adopt_onboard,
    },
  ]
  const dataService = [
    {
      title: translate.t?.estate_brands,
      description: translate.t?.help_producers_full_potential,
      img: f1,
      details: [
        translate.t?.inspired_by_vineyard,
        translate.t?.save_future_coffee,
      ],
    },
    {
      title: translate.t?.new_business_opportunities,
      description: translate.t?.achieve_intl_global_brand,
      img: f2,
      details: [
        translate.t?.building_profile_on_our_platform,
        translate.t?.promote_through_coffee_lab,
      ],
    },
    {
      title: translate.t?.ec_exp,
      description: translate.t?.ultimate_exp_qr_sol,
      img: f3,
      details: [
        translate.t?.branded_webapp_opp,
        translate.t?.connect_consumers_beyond_bar_clp,
      ],
    },
    {
      title: translate.t?.friendly_communication,
      description: translate.t?.collab_community_ug_content,
      img: f4,
      details: [
        translate.t?.no_language_barrier,
        translate.t?.our_content_user_generated,
      ],
    },
    {
      title: translate.t?.elevated_sourcing,
      description: translate.t?.no_matter_what_amount,
      img: f5,
      details: [
        translate.t?.we_know_transparency,
        translate.t?.with_opp_partner,
      ],
    },
    {
      title: translate.t?.digital_transformation,
      description: translate.t?.increase_efficiency,
      img: f6,
      details: [translate.t?.eraofwe_api_makes, translate.t?.several_dm_tools],
    },
  ]

  const microData = [
    {
      img: micro1,
      title: translate.t?.platform_access,
      description: translate.t?.get_invite_eraofwe_roaster_region,
    },
    {
      img: micro2,
      title: translate.t?.source_locally_or_directly,
      description: translate.t?.find_what_gb_located,
    },
    {
      img: micro3,
      title: translate.t?.fast_delivery,
      description: translate.t?.deliver_greenbeans,
    },
  ]
  return (
    <>
      {
        <div className="roaster_page micro-roaster">
          <div className="content">
            <Hero
            pageContext={{ translate }}
              pagename={translate.t?.eraofwe_for_mr}
              title={translate.t?.find_next_trending}
              description={translate.t?.digital_source_niche_unique}
              btntext={translate.t?.book_a_demo}
              img={heroimage}
              btnLink=""
              model="contact"
            />
            <Oppurtunity
              pageContext={{ translate }}
              heading={translate.t?.new_opp_roasters_alike}
              btnText=""
              btnLink=""
              data={dataOppurtunity}
            />
            <Origin
              pageContext={{ translate }}
              heading={translate.t?.show_others_c2c}
              description={translate.t?.get_insights_land_lots}
              btnText={translate.t?.how_it_works}
              btnLink={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }traceability/`}
            />
            <Experience
              pageContext={{ translate }}
              title={translate.t?.exp_roasters_love}
              btnText={translate.t?.view_our_community}
              btnLink={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }community/`}
              img={Fredrik}
              name="Fredrik Nilsson"
              designation="Löfbergs"
              pagetitle="CEO"
              description={translate.t?.public_quote_3}
            />
            <div className="services-new microRoaster">
              <Services
                pageContext={{ translate }}
                title={translate.t?.benefits_for_mr}
                description={translate.t?.from_streamlined_mgmt}
                data={dataService}
                btnText={translate.t?.contact_us}
                btnLink={`${
                  translate.currentLang
                    ? "/" + translate.currentLang + "/"
                    : "/"
                }contact/`}
              />
            </div>
            <Micro
              pageContext={{ translate }}
              pagename={translate.t?.specialty_roaster}
              title={translate.t?.from_estate_straight_digital_exp}
              description={translate.t?.get_access_eraofwe_community}
              data={microData}
            />
            <div className="roaster-page">
              <Relationship pageContext={{ translate }} isMicroRoaster />
            </div>
            <div className="facilitator estate">
              <TeamWork
                pageContext={{ translate }}
                heading={translate.t?.great_teamwork_commitment}
                btnText={translate.t?.download_whitepaper}
                btnLink=""
                modal="download"
                data={dataTeam}
                bottom={false}
                flex={true}
              />
            </div>
            <div className="joiner-second">
              <Join pageContext={{ translate }} />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default RoasterPage
