import * as React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import RoasterPage from "../components/microroaster";
import languages from "../utils/enums/languages";

const MicroRoaster = ({ location, pageContext: { translate } }) => (
  <Layout secondary pageContext={{ translate }} path="partners" location={location} SEO={({ data }) =>
    <SEO
      title={data.t?.mr_page_title}
      description={data.t?.mr_metad}
      keywords={data.t?.keywords_mr}
      languages={[...languages.map((l) => {
        return {
          hrefLang: l || 'en',
          href: `${process.env.GATSBY_SITE_URL}${l ? '/' + l + '/' : '/'}micro-roaster/`
        }
      }), {
        hrefLang: 'x-default',
        href: `${process.env.GATSBY_SITE_URL}/micro-roaster/`
      }]}
    />
  }>
    <>
      {
        (
          <>
            <RoasterPage pageContext={{ translate }} />
          </>
        )
      }
    </>
  </Layout>
)

export default MicroRoaster
